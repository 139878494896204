import { Chip, CircularProgress, Paper, Stack, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React, { useEffect } from "react";
import { useStores } from "../../../common/modules/useStores";
import { useParams } from "react-router-dom";

export const License = observer(() => {

    const { bizGroupStore, siteLicenseStore } = useStores();

    const { licenses } = siteLicenseStore;
    const { bizGroupId:currBizGroupId } = useParams();

    useEffect(()=>{
        if(currBizGroupId !== -1) {
            siteLicenseStore.findAll({bizGroupId:currBizGroupId});
        }
    }, [currBizGroupId])

    if (siteLicenseStore.isFetching) {
        return (
            <Stack alignItems={"center"} minHeight={'100vh'} justifyContent={'flex-start'} p={4} bgcolor={'#F3F6F9'}>
                <CircularProgress/>
            </Stack>
        )
    }

    return (licenses.length ? (
                [...licenses].sort((a, b) => moment(b.finishedDate)
                .diff(moment(a.finishedDate)))
                .map(({name, startedDate, finishedDate, maxUsersCnt, fullContract, domain}, idx) => (
                <Stack component={Paper} sx={{ width: '100%', mb: 2, p: 3, opacity: idx === 0 ? 1:0.5, '&:hover': {opacity: 1}}} spacing={2}>
                    <Typography variant="h4">
                        {name}
                        {idx === 0 && (<Chip label={'최신'}/>)}
                    </Typography>

                    <Stack>
                    <Typography variant="body1">정원</Typography>
                    <Typography variant="h6">{fullContract ?  `무제한` : maxUsersCnt}</Typography>
                    </Stack>

                    <Stack>
                    <Typography variant="body1">정원</Typography>
                    <Typography variant="h6">{fullContract ?  `무제한` : maxUsersCnt}</Typography>
                    </Stack>

                    <Stack>
                    <Typography variant="body1">계약 기간</Typography>
                    <Typography variant="h6">{`${moment(startedDate).format('YYYY-MM-DD')} ~ ${moment(finishedDate).format('YYYY-MM-DD')}`}</Typography>
                    </Stack>

                    <Stack>
                    <Typography variant="body1">유효 도메인</Typography>
                    <Typography variant="h6">{domain}</Typography>
                    </Stack>
                </Stack>
            ))): (
                <Typography>라이센스 계약이 필요합니다</Typography>
            ))
})
