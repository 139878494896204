import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import {
    FormControl,
    Radio,
    RadioGroup,
    FormControlLabel,
    CircularProgress, Button, Menu, MenuItem
} from '@mui/material'
import Stack from "@mui/material/Stack";
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { visuallyHidden } from '@mui/utils';
import moment from 'moment';
import 'moment/locale/ko';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../common/modules/useStores';
import { useParams } from 'react-router-dom';
import * as XLSX from 'xlsx';

import Divider from "@mui/material/Divider";
import AddIcon from "@mui/icons-material/Add";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

moment.locale('ko')

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    // {
    //     id: 'name',
    //     numeric: false,
    //     disablePadding: true,
    //     label: '이름',
    // },
    {
        id: 'username',
        numeric: false,
        dateType: false,
        disablePadding: true,
        label: '아이디',
    },
    {
        id: 'studentEmail',
        numeric: false,
        dateType: false,
        disablePadding: true,
        label: '이메일',
    },
    {
        id: 'lastLogin',
        numeric: false,
        dateType: true,
        disablePadding: true,
        label: '최근 접속일',
    },
    {
        id: 'solvedNum',
        numeric: true,
        dateType: false,
        disablePadding: true,
        label: '푼 문제수',
    },
    {
        id: 'testCompletionRate',
        numeric: false,
        dateType: false,
        disablePadding: true,
        label: '실력체크 이수율',
    },
    {
        id: 'totalCompletionRate',
        numeric: false,
        dateType: false,
        disablePadding: true,
        label: '전체 이수율',
    },
];

function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'left'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
    // 엑셀 다운로드 start
    const { numSelected, userFilterSets, selectedFilterSet, handleSelectedFilterSet, members } = props;

    const [anchorEl, setAnchorEl] = useState(null);
    const userFilterAll = [{ name: "전체 학생 목록" }, ...userFilterSets];

    const handleExcelDownload = (members) => {
        const headers = ['아이디', '이메일', '최근 접속일', '푼 문제 수', '실력체크 이수율', '전체 이수율'];
        const dataset = members.map(row => [
            row.username,
            row.studentEmail,
            row.lastLogin ? moment(row.lastLogin).format('MMMM Do YYYY, h:mm:ss a') : '',
            row.solvedNum,
            row.testCompletionRate,
            row.totalCompletionRate,
        ]);

        const worksheet = XLSX.utils.aoa_to_sheet([headers, ...dataset]);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        const excelFile = XLSX.write(workbook, { type: 'array', bookType: 'xlsx' });

        const blob = new Blob([excelFile], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', '학습 현황.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }; //end
    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} 명의 학생 선택됨
                </Typography>
            ) : (

                    <Stack direction={"row"} spacing={1} width={1} sx={{justifyContent: 'space-between'}}>
                        <Typography
                            sx={{ flex: '1 1 100%' }}
                            variant="h6"
                            id="tableTitle"
                            component="div"
                        >
                            학생별 학습 현황
                        </Typography>
                        <>
                            <Button
                                onClick={(e) => setAnchorEl(e.currentTarget)}
                                sx={{
                                    padding: 2,
                                    borderRadius: 2,
                                    width: "400px"
                                }}
                            >
                                <Box
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    width="100%"
                                >
                                    <Box display="flex" flexDirection="column">
                                        {selectedFilterSet && selectedFilterSet.id ? (
                                            <Stack direction={"column"}>
                                                <Stack
                                                    direction="row"
                                                    justifyContent={"space-between"}
                                                    alignItems={"center"}
                                                >
                                                    <Typography
                                                        variant="body1"
                                                        textAlign="left"
                                                        fontWeight={700}
                                                    >
                                                        {selectedFilterSet.name}
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                        ) : (
                                            <Typography variant="body2" textAlign="left">
                                                전체 학생 목록
                                            </Typography>
                                        )}
                                    </Box>
                                    <ArrowDropDownIcon />
                                </Box>
                            </Button>
                            <Menu
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                                open={Boolean(anchorEl)}
                                onClose={() => setAnchorEl(null)}
                                MenuListProps={{
                                    "aria-labelledby": "basic-button",
                                    sx: { width: anchorEl && anchorEl.offsetWidth },
                                }}
                                PaperProps={{
                                    style: {
                                        maxHeight: 300,
                                    },
                                }}
                            >
                                {userFilterAll.map((filterSet, idx) => (
                                    <>
                                        {idx === 1 ? <Divider /> : null}
                                        <MenuItem
                                            key={`user-filter-set-${filterSet.id}`}
                                            onClick={() => {
                                                handleSelectedFilterSet(filterSet);
                                                setAnchorEl(null);
                                            }}
                                            selected={
                                                selectedFilterSet
                                                    ? selectedFilterSet.id === filterSet.id
                                                    : false
                                            }
                                        >
                                            <Stack direction="column">
                                                <Typography variant="body2" textAlign="left">
                                                    {filterSet.id ? `${filterSet.name}(${filterSet.emailSet.length})`
                                                        : filterSet.name}
                                                </Typography>
                                            </Stack>
                                        </MenuItem>
                                    </>
                                ))}
                            </Menu>
                        </>
                        <Button variant='contained' onClick={() => handleExcelDownload(members)}  startIcon={<AddCircleIcon />} sx={{flexShrink: 0}}>
                            엑셀 다운로드
                        </Button>
                    </Stack>

            )}
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export const DashboardContainer = observer(({}) => {

    const { memberStore, bizGroupStore, siteLicenseStore } = useStores();
    const { members } = memberStore;
    const { latestLicense, userFilterSets, selectedFilterSet, setFilterSet } = siteLicenseStore;
    const { bizGroupId:currBizGroupId } = useParams();

    const groupFilterType = {
        All: 'all',
        Verified: 'verified',
        Unproved: 'unproved'
    }

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [dense, setDense] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [groupValue, setGroupValue] = useState(groupFilterType.All)

    useEffect(() => {
        if(currBizGroupId !== -1) {
            setFilterSet(undefined);
            memberStore.findAll({bizGroupId: currBizGroupId});
            siteLicenseStore.findAll({bizGroupId: currBizGroupId});
            siteLicenseStore.findUserFilterSetAll({bizGroupId: currBizGroupId});
        }
    }, [currBizGroupId])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = members.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleSelectedFilterSet = (filterSet) => {
        setFilterSet(filterSet);
        memberStore.findAll({bizGroupId: currBizGroupId, userFilterSetId: filterSet.id});
    }

    const handleClick = (event, member) => {
        const selectedIndex = selected.findIndex(m => m.studentEmail === member.studentEmail);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = [...selected, member];
        } else {
            newSelected = [...selected.slice(0, selectedIndex), ...selected.slice(selectedIndex+1)]
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleGroupChange = e => {
        setGroupValue(e.target.value)
    }

    const isSelected = (member) => selected.findIndex(m => m.studentEmail === member.studentEmail) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - members.length) : 0;

    if (memberStore.isFetching || siteLicenseStore.isFetching) {
        return (
            <Stack alignItems={"center"} minHeight={'100vh'} justifyContent={'flex-start'} p={4} bgcolor={'#F3F6F9'}>
                <CircularProgress/>
            </Stack>
        )
    }

    return (latestLicense ? (
            <Paper sx={{ width: '100%', mb: 2 }}>
                <EnhancedTableToolbar
                    numSelected={selected.length}
                    members={members}
                    userFilterSets={userFilterSets}
                    selectedFilterSet={selectedFilterSet}
                    handleSelectedFilterSet={handleSelectedFilterSet}
                />
                <FormControl sx={{px: 2}}>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={groupValue}
                        onChange={handleGroupChange}
                    >
                        <FormControlLabel value={groupFilterType.All} control={<Radio />} label={`전체(${members.length})`} />
                        <FormControlLabel value={groupFilterType.Verified} control={<Radio />} label={`인증됨(${members.filter(({verifiedDate}) => verifiedDate).length})`} />
                        <FormControlLabel value={groupFilterType.Unproved} control={<Radio />} label={`인증전(${members.filter(({verifiedDate}) => verifiedDate===null).length})`} />
                    </RadioGroup>
                </FormControl>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={members.length}
                        />
                        <TableBody>
                            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 members.slice().sort(getComparator(order, orderBy)) */}
                            {stableSort(
                                members.map(member => ({
                                    ...member,
                                    lastLogin: member.lastLogin !== null ? moment(member.lastLogin).valueOf() : 0,
                                    verifiedDate: member.verifiedDate !== null ? moment(member.verifiedDate).valueOf() : 0,
                                })),
                                getComparator(order, orderBy))
                                .filter(row => {
                                    if (groupValue === groupFilterType.All) {
                                        return true
                                    } else if (groupValue === groupFilterType.Verified) {
                                        return row.verifiedDate
                                    } else {
                                        return row.verifiedDate === null
                                    }
                                })
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.studentEmail}
                                            selected={isItemSelected}
                                        >
                                            <TableCell>{row.username}</TableCell>
                                            <TableCell>{row.studentEmail}</TableCell>
                                            <TableCell>{row.lastLogin ? moment(row.lastLogin).format('MMMM Do YYYY, h:mm:ss a') : ''}</TableCell>
                                            <TableCell>{row.solvedNum}</TableCell>
                                            <TableCell>{row.testCompletionRate}</TableCell>
                                            <TableCell>{row.totalCompletionRate}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

            </Paper>):(
                <Typography>라이센스 계약이 필요합니다</Typography>
            ));
})
