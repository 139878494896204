import {makeAutoObservable, flow} from "mobx";
import {
    getToken,
    removeRefreshToken,
    removeToken,
    setRefreshToken,
    setToken
} from "../../../utils/cookies";
import AuthRepository from "./AuthRepository";
import {UserModel} from "./UserModel";
import {errorToast} from "../../../utils/errorToast";

export default class AuthStore {
    currUser = undefined;
    isFetching = true;

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this, {
            login: flow,
            getUserFromToken: flow,
            updatePassword: flow,
        }, { autoBind: true });
        if(this.hasToken()) {
            this.getUserFromToken();
        }
    }

    redirectToNextUrl({ nextUrl }) {
        if(nextUrl) {
            const decodedUrl = decodeURIComponent(nextUrl);
            window.location.href = decodedUrl;
        } else {
            window.location.href = "/";
        }
    }

    *login({ body, nextUrl }) {
        try {
            this.isFetching = true;
            const response = yield AuthRepository.login({ body });
            this.isFetching = false;
            if (response.status === 203) {
                const { data } = response;
                window.location.href = `/accounts/need-to-verify?uid=${data.uid}`;
                return;
            }

            const { access, refresh } = response.data;
            setToken(access);
            setRefreshToken(refresh);
            this.redirectToNextUrl({nextUrl});
        } catch (e) {
            errorToast(e);
        }
    }

    logout() {
        removeToken();
        removeRefreshToken();
        window.location.href = "/";
        this.currUser = undefined;
    };

    *getUserFromToken() {
        try {
            const response = yield AuthRepository.verifyToken();
            this.currUser = new UserModel(response.data);
        } catch (e) {
            removeToken();
            alert(e);
            window.location.href = "/";
        }
    }

    *updatePassword({ body }) {
        const response = yield AuthRepository.updatePassword({ body });
        return response;
    }

    hasToken() {
        const token = getToken();
        return !!token;
    }

    isUserLoggedIn() {
        return this.hasToken() && !!this.currUser;
    }
}
