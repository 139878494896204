import { CircularProgress } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react'
import {Navigate, Route, Routes, useParams} from "react-router-dom";
import { useStores } from '../../common/modules/useStores';
import { CollegeTemplate } from './CollegeTemplate';

export const CollegeContainer = observer(() => {

    const { bizGroupStore } = useStores();

    const { bizGroups, isFetching, selectBizGroupId } = bizGroupStore

    return (
        isFetching ? <CircularProgress/> :
        bizGroups.length === 0 ? <div>관리자로 지정된 학교가 없습니다.</div> :
        <Routes>
            <Route index element={<Navigate to={`${bizGroups[0].id}`} replace/>}/>
            <Route path=":bizGroupId/*" element={
                <CollegeTemplate
                    bizGroups={bizGroups}
                    selectBizGroupId={selectBizGroupId}/>
            }/>
        </Routes>
    )
})
