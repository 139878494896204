import {makeAutoObservable, set} from "mobx";
import {snakelizeKeys} from "../../../utils/renameKeys";
import {camelizeKeys} from "../../../utils/renameKeys";
import {pickCls} from "../../../utils/immutableLodash";
import moment from "moment";

export class MemberModel {
    id = 0;

    username = "";

    name = "";

    studentEmail = "";

    lastLogin = null;

    solvedNum = 0;

    testCompletionRate = "";

    totalCompletionRate = "";

    requestedAt = moment();

    verifiedDate = moment();

    constructor(data) {
        makeAutoObservable(this, {}, {autoBind: true});
        const reply = pickCls(
            this,
            camelizeKeys(data)
        );
        set(this, reply);
    }
}
