import React, { useEffect, useState } from "react"
import {useStores} from "../../common/modules/useStores"
import { Stack } from "@mui/material"
import { observer } from "mobx-react-lite"
import { searchParamsToObject } from "../../utils/url"
import { LoginTempalte } from "./LoginTemplate"
import { Navigate, useLocation } from "react-router-dom"


export const LoginContainer = observer(({ }) => {
    const location = useLocation();
    const { authStore } = useStores();

    const [inputs, setInputs] = useState({
        username: "",
        password: "",
    })

    const login = ({ username, password }) => {
        const params = searchParamsToObject(window.location.search);
        const nextUrl = params.nextUrl || location.state?.from?.pathname || "/";

        authStore.login({
            body: { username, password },
            nextUrl,
        });
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputs({
            ...inputs,
            [name]: value
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        login(inputs);
    }

    if (authStore.isUserLoggedIn()) {
        const params = searchParamsToObject(window.location.search);
        const nextUrl = params.nextUrl || location.state?.from?.pathname || "/";
        return <Navigate to={nextUrl} replace/>
    }

    return (
        <LoginTempalte
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            inputs={inputs}
        />
    )
})
