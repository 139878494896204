import axios from "axios";
import {getRefreshToken, getToken, removeRefreshToken, removeToken, setToken} from "./cookies";

let isTokenRefreshing = false;
let refreshSubscribers = [];

axios.defaults.baseURL = `${process.env.REACT_APP_API_HOST}/api/v1`;

const onTokenRefreshed = (accessToken) => {
    refreshSubscribers.map((callback) => callback(accessToken));
};

const clearRefreshSubscribers = () => {
    refreshSubscribers = [];
}

const addRefreshSubscriber = (callback) => {
    refreshSubscribers.push(callback);
};

axios.interceptors.response.use(
    (response) => response,
    async (error) => {
        if (!error.response) {
            return Promise.reject(error);
        }

        if (error.response.status === 401
            && error.config.url !== "api-token-refresh/"
        ) {
            const originalRequest = error.config;

            let access;

            const refresh = getRefreshToken();

            if (!isTokenRefreshing && refresh) {
                isTokenRefreshing = true;

                await unauthorizedPOST(
                    "api-token-refresh/",
                    {
                        refresh,
                    }
                )
                    .then((response) => {
                        access = response.data?.access;
                        setToken(access);
                    })
                    .catch(e => {
                        removeRefreshToken();
                        removeToken();
                        alert(
                            "로그인한지 30일이 경과되어 자동으로 로그아웃 되었습니다."
                        );
                        window.location.reload();
                    })
                    .finally(() => {
                        isTokenRefreshing = false;
                    });
            }

            const retryOriginalRequest = refresh ? new Promise((resolve) => {
                addRefreshSubscriber((accessToken) => {
                    originalRequest.headers.Authorization = "JWT " + accessToken;
                    resolve(axios(originalRequest));
                })
            }) : await Promise.reject(error);

            if (!isTokenRefreshing) {
                if(access) onTokenRefreshed(access);
                clearRefreshSubscribers();
            }

            return retryOriginalRequest;
        }

        if (error.response.status === 404) {
            window.location.replace("/not-found");
        }
        return Promise.reject(error);
    }
);

const config = () => ({
    headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${getToken()}`,
    },
});

const paramConfig = (param) => ({
    headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${getToken()}`,
    },
    params: param,
});

const dataConfig = (data) => ({
    headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${getToken()}`,
    },
    data,
});

const formdataConfig = () => ({
    headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `JWT ${getToken()}`,
    },
});

const unauthorizedConfig = {
    headers: {
        "Content-Type": "application/json",
    },
};

const inactiveConfig = (token) => ({
    headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
    },
});

export const GET = async (url) => axios.get(url, config());
export const DELETE = async (url, data) => axios.delete(url, dataConfig(data));
export const POST = async (url, data) => axios.post(url, data, config());
export const PUT = async (url, data) => axios.put(url, data, config());
export const PATCH = async (url, data) => axios.patch(url, data, config());

export const inactivePATCH = async (url, data, token) =>
    axios.patch(url, data, inactiveConfig(token));

export const unauthorizedGET = async (url) =>
    axios.get(url, unauthorizedConfig);
export const paramGET = async (url, param) =>
    axios.get(url, paramConfig(param));
export const unauthorizedPOST = async (url, data) =>
    axios.post(url, data, unauthorizedConfig);

export const postFormData = async (url, formData) =>
    axios.post(url, formData, formdataConfig());
