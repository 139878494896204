import React, {useEffect} from 'react';
import { Avatar, Typography, useTheme } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from "@mui/material/Stack";
import { generatePath, matchRoutes, Navigate, Outlet, useLocation, useNavigate, useMatch, Routes, Route, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from '../common/modules/useStores'
import { LogoButton } from '../common/components/button/LogoButton';
import { LicenseSelect } from './LicenseSelect';
import { blueGrey } from "@mui/material/colors"
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import GroupsIcon from '@mui/icons-material/Groups';
import PasswordIcon from '@mui/icons-material/Password';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import PaymentsIcon from '@mui/icons-material/Payments';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { CollegePage } from './College/CollegePage';
import { LoginContainer } from './Accounts/LoginContainer';
import { Logout } from './Accounts/Logout';
import { PasswordContainer } from './Accounts/PasswordContainer'

const drawerWidth = 240;

const LIST_ITEMS = [
	{
		label: '학습 현황',
		icon: <MonitorHeartIcon />,
		requireAuth: true,
		path: "/college/:bizGroupId/dashboard"
	},
    {
        label: '역량평가 현황',
        icon: <AssessmentIcon />,
        requireAuth: true,
        path: "/college/:bizGroupId/level-test"
    },
	{
		label: '학생 목록 편집',
		icon: <GroupsIcon />,
		requireAuth: true,
		path: "/college/:bizGroupId/members"
	},
	{
		label: '라이센스 관리',
		icon: <PaymentsIcon />,
		requireAuth: true,
		path: "/college/:bizGroupId/license"
	},
	{
		divider: true
	},
	{
		label: '비밀번호 변경',
		icon: <PasswordIcon />,
		requireAuth: true,
		path: "/change-password"
	},
	{
		label: '로그아웃',
		icon: <LogoutIcon />,
		requireAuth: true,
		path: "/logout"
	},
	{
		label: '로그인',
		icon: <LoginIcon />,
		onlyUnAuth: true,
		path: "/login"
	}
]

export const MainPage = observer(() => {
    const { authStore, bizGroupStore } = useStores();
    const { isUserLoggedIn, currUser } = authStore;
    const { bizGroups, currBizGroupId, selectBizGroupId } = bizGroupStore;

    useEffect(() => {
		if(isUserLoggedIn()){
        bizGroupStore.findAll()
		}
    }, [currUser])


    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();

    const routes = LIST_ITEMS.filter(item => item.path).map(({path}) => ({path}))

    const matched = matchRoutes(routes, location)

    const handleSelect = nextBizGroupId => {
        selectBizGroupId(nextBizGroupId)
        const matched = matchRoutes(routes, location)
        if (matched) {
            const [{route}] = matched
            navigate(generatePath(route.path, {bizGroupId: nextBizGroupId}));
        }
    }

    const handleNavLink = path => () => {
        if (currBizGroupId !== -1){
            navigate(generatePath(path, {bizGroupId:currBizGroupId}))
        } else {
            navigate(generatePath(path, {bizGroupId:bizGroups[0]?.id}))
        }
    }

    return (
        <Stack direction="row">
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="permanent"
                anchor="left"
            >
                {isUserLoggedIn() ?
                    <Stack>
                        <Stack direction="row" spacing={1} alignItems={'center'} px={2} pt={3}>
                            <Avatar sx={{ bgcolor: blueGrey[500] }}>{currUser.username[0].toUpperCase()}</Avatar>
                            <Typography>{currUser.username}</Typography>
                        </Stack>
                        <LicenseSelect
                            loading={bizGroupStore.isFetching}
                            bizGroups={bizGroups}
                            currBizGroupId={currBizGroupId}
                            handleSelect={handleSelect}
                            selectBizGroupId={selectBizGroupId}
                        />
                    </Stack>
                    :
                    <Toolbar ><LogoButton /></Toolbar>}
                <Divider />

                {LIST_ITEMS.map(({ divider, label, icon, path, requireAuth, onlyUnAuth }) => {
                    if (divider) {
                        return <Divider />
                    }
                    else if ((isUserLoggedIn() && onlyUnAuth) || (!isUserLoggedIn() && requireAuth)) {
                        return null
                    }
                    else {
                        const isSelected = matched === null ?  false : matched[0].route.path === path
                        return (
                            <ListItem key={label} disablePadding>
                                <ListItemButton
                                    onClick={handleNavLink(path)}
                                    selected={isSelected}>
                                    <ListItemIcon>
                                        {React.cloneElement(icon, isSelected ? {color: "primary"}: {})}
                                    </ListItemIcon>
                                    <ListItemText primary={label} sx={isSelected ? {color: theme.palette.primary.main}: null} />
                                </ListItemButton>
                            </ListItem>
                        )
                    }
                })}

            </Drawer>
            <Stack flex={1}>
                <Routes>
                    <Route index element={<Navigate to={`college`} replace/>}/>
					<Route path="college/*" element={
						<RequireAuth>
							<CollegePage/>
						</RequireAuth>
					}/>
                    <Route path="change-password/*" element={
                        <RequireAuth>
                            <PasswordContainer/>
                        </RequireAuth>
                    }/>
					<Route path="login/*" element={<LoginContainer />} />
					<Route path="logout/*" element={
						<Logout />
					} />

				</Routes>
            </Stack>
        </Stack>
    )
})

const RequireAuth = observer(({ children }) => {
	const { authStore } = useStores();
	const location = useLocation();

	if (!authStore.isUserLoggedIn()) {
		return <Navigate to="/login" state={{ from: location }} replace />
	}

	return children;
})
