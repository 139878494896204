import { CircularProgress } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react'
import {Navigate, Route, Routes, useLocation, useNavigate, useParams} from "react-router-dom";
import { useStores } from '../../common/modules/useStores';
import { DashboardContainer } from './Dashboard/DashboardContainer';
import { License } from './License/LicenseContainer';
import { MembersContainer } from './Members/MembersContainer';
import { Stack } from '@mui/material';
import { LevelTestContainer } from './LevelTest/LevelTestContainer';

export const CollegeTemplate = observer(({bizGroups, selectBizGroupId}) => {

    const { bizGroupId } = useParams();

    const navigate = useNavigate();

    useEffect(()=>{
        if (bizGroups.some(({id}) => id === Number(bizGroupId))){
            selectBizGroupId(bizGroupId)
        } else {
            navigate('/college', {replace: true})
        }
    }, [bizGroupId])



    return (
        <Stack alignItems={"center"} minHeight={'100vh'} justifyContent={'flex-start'} p={4} bgcolor={'#F3F6F9'}>
            <Routes>
                <Route index element={<Navigate to={`dashboard`} replace/>}/>
                <Route path="dashboard" element={<DashboardContainer/>}/>
                <Route path="level-test" element={<LevelTestContainer/>}/>
                <Route path="members" element={<MembersContainer/>}/>
                <Route path="license" element={<License/>}/>
            </Routes>
        </Stack>
    )
})
