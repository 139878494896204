import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import {
    Stack,
    Box,
    Table,
    TableRow,
    Button,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableSortLabel,
    Toolbar,
    Typography,
    Paper,
    Checkbox,
    IconButton,
    Tooltip,
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText,
    FormControl,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormLabel,
    CircularProgress
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { visuallyHidden } from '@mui/utils';
import moment from 'moment';
import { Check } from '@mui/icons-material';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons/faFileCsv';
import { faAddressCard } from '@fortawesome/free-solid-svg-icons/faAddressCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AddMemberDialog, DIALOG_TYPE } from "./AddMemberDialog";
import { DeleteMemberDialog } from './DeleteMemberDialog';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../common/modules/useStores';
import { useNavigate, useParams, Navigate } from 'react-router-dom';
import Divider from "@mui/material/Divider";
import AddIcon from "@mui/icons-material/Add";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {FilterSetAddDialog} from "./FilterSetAddDialog";
import {UserFilterSetDAOModel} from "../../../common/modules/siteLicense/UserFilterSetModel";
import {snackActions} from "../../../utils/SnackbarUtilsConfigurator";
import {errorToast} from "../../../utils/errorToast";


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    // {
    //     id: 'name',
    //     numeric: false,
    //     disablePadding: true,
    //     label: '이름',
    // },
    {
        id: 'username',
        numeric: false,
        disablePadding: true,
        label: '아이디',
    },
    {
        id: 'studentEmail',
        numeric: false,
        disablePadding: true,
        label: '이메일',
    },
    // {
    //     id: 'requestedAt',
    //     numeric: false,
    //     disablePadding: true,
    //     label: '추가 날짜',
    // },
    {
        id: 'verified',
        numeric: false,
        disablePadding: true,
        label: '인증 여부',
    },
    {
        id: 'verifiedDate',
        numeric: true,
        disablePadding: true,
        label: '인증 날짜',
    },
];

function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
    const { siteLicenseStore } = useStores();

    const { numSelected, members, addMembers, deleteMembers, latestLicense, clearSelected, currBizGroup, userFilterSets } = props;
    const { selectedFilterSet, handleSelectedFilterSet } = props;
    const navigate = useNavigate();
    const [memberAnchorEl, setMemberAnchorEl] = useState(null);
    const [filterSetAnchorEl, setFilterSetAnchorEl] = useState(null);
    const [addFilterSetDialogOpen, setAddFilterSetDialogOpen] = useState(false);
    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [dialogType, setDialogType] = useState(DIALOG_TYPE.Manual)
    const userFilterAll = [{ name: "전체 학생 목록" }, ...userFilterSets];

    const handleMemberAddClick = (event) => {
        setMemberAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setMemberAnchorEl(null);
    };


    const handleDialogOpen = requestedDialogType => {
        setDialogType(requestedDialogType)
        setAddDialogOpen(true);
    };

    const handleAddDialogClose = () => {
        setAddDialogOpen(false);
    };

    const handleAddConfirm = membersToAdd => {
        addMembers(membersToAdd)
    }

    const handleDeleteDialogClose = () => {
        setDeleteDialogOpen(false);
    };

    const handleDeleteConfirm = () => {
        deleteMembers()
    }

    const handleDeleteUserFilterSet = () => {
        if(window.confirm(`이 작업은 되돌릴 수 없습니다. 정말 ${selectedFilterSet.name} 학급을 삭제하시겠습니까?`)) {
            siteLicenseStore.deleteUserFilterSet({bizGroupId: currBizGroup.id, userFilterSetId: selectedFilterSet.id})
                .then(() => {
                    snackActions.success("성공적으로 삭제되었습니다.");
                })
                .catch((e) => {
                    errorToast(e);
                })
        }
    };

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.error.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} 명의 학생 선택됨
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    {selectedFilterSet ? selectedFilterSet.name : "전체 학생 목록"}
                    <Button onClick={()=>navigate(`../license`)}>{`(${members.length}명 / ${latestLicense.fullContract ? `무제한` :  latestLicense.maxUsersCnt}명)`}</Button>
                    {selectedFilterSet && selectedFilterSet.id ?
                        (
                            <Button variant='contained' onClick={handleDeleteUserFilterSet} startIcon={<DeleteIcon />} color={'error'} sx={{flexShrink: 0, marginLeft: 2}}>
                                학급 삭제
                            </Button>
                        )
                        : null}
                </Typography>
            )}

            {numSelected > 0 ? (
                <Stack direction={"row"} spacing={1}  sx={{flexShrink: 0}}>
                    <Button variant='outlined' onClick={clearSelected} sx={{flexShrink: 0}}>모두 선택 해제</Button>
                    <Button variant='contained' onClick={()=>setDeleteDialogOpen(true)} startIcon={<DeleteIcon />} color={'error'} sx={{flexShrink: 0}}>
                        삭제
                    </Button>
                </Stack>
            ) : (
                <>
                    <>
                        <Button
                            onClick={(e) => setFilterSetAnchorEl(e.currentTarget)}
                            sx={{
                                padding: 2,
                                borderRadius: 2,
                                width: "400px"
                            }}
                        >
                            <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                                justifyContent="space-between"
                                width="100%"
                            >
                                <Box display="flex" flexDirection="column">
                                    {selectedFilterSet && selectedFilterSet.id ? (
                                        <Stack direction={"column"}>
                                            <Stack
                                                direction="row"
                                                justifyContent={"space-between"}
                                                alignItems={"center"}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    textAlign="left"
                                                    fontWeight={700}
                                                >
                                                    {selectedFilterSet.name}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                    ) : (
                                        <Typography variant="body2" textAlign="left">
                                            전체 학생 목록
                                        </Typography>
                                    )}
                                </Box>
                                <ArrowDropDownIcon />
                            </Box>
                        </Button>
                        <Menu
                            anchorEl={filterSetAnchorEl}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            open={Boolean(filterSetAnchorEl)}
                            onClose={() => setFilterSetAnchorEl(null)}
                            MenuListProps={{
                                "aria-labelledby": "basic-button",
                                sx: { width: filterSetAnchorEl && filterSetAnchorEl.offsetWidth },
                            }}
                            PaperProps={{
                                style: {
                                    maxHeight: 300,
                                },
                            }}
                        >
                            {userFilterAll.map((filterSet, idx) => (
                                <>
                                    {idx === 1 ? <Divider /> : null}
                                    <MenuItem
                                        key={`user-filter-set-${filterSet.id}`}
                                        onClick={() => {
                                            handleSelectedFilterSet(filterSet);
                                            setFilterSetAnchorEl(null);
                                        }}
                                        selected={
                                            selectedFilterSet
                                                ? selectedFilterSet.id === filterSet.id
                                                : false
                                        }
                                    >
                                        <Stack direction="column">
                                            <Typography variant="body2" textAlign="left">
                                                {filterSet.id ? `${filterSet.name}(${filterSet.emailSet.length})`
                                                : filterSet.name}
                                            </Typography>
                                        </Stack>
                                    </MenuItem>
                                </>
                            ))}
                            <>
                                <Divider />
                                <MenuItem onClick={() => setAddFilterSetDialogOpen(!addFilterSetDialogOpen)}>
                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                        width="100%"
                                    >
                                        <Typography
                                            variant="body1"
                                            textAlign="left"
                                            fontWeight={700}
                                            children="새 학급 만들기"
                                        />
                                        <AddIcon />
                                    </Stack>
                                </MenuItem>
                            </>
                        </Menu>
                        <FilterSetAddDialog
                            open={addFilterSetDialogOpen}
                            onClose={() => setAddFilterSetDialogOpen(false)}
                            bizGroupId={currBizGroup && currBizGroup.id}
                        />
                    </>
                    <Button variant='contained' onClick={handleMemberAddClick} startIcon={<AddCircleIcon />} sx={{flexShrink: 0}}>
                        학생 추가
                    </Button>
                    <Menu
                        id="basic-menu"
                        anchorEl={memberAnchorEl}
                        open={Boolean(memberAnchorEl)}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem onClick={() => {
                            handleDialogOpen(DIALOG_TYPE.File);
                            handleClose();
                        }}>
                            <ListItemIcon>
                                <FontAwesomeIcon icon={faFileCsv} />
                            </ListItemIcon>
                            <ListItemText>엑셀 업로드</ListItemText>
                        </MenuItem>
                        <MenuItem onClick={() => {
                            handleDialogOpen(DIALOG_TYPE.Manual);
                            handleClose();
                        }}>
                            <ListItemIcon>
                                <FontAwesomeIcon icon={faAddressCard} />
                            </ListItemIcon>
                            <ListItemText>직접 입력</ListItemText>
                        </MenuItem>
                    </Menu>
                </>
            )}
            <AddMemberDialog
                open={addDialogOpen}
                handleClose={handleAddDialogClose}
                dialogType={dialogType}
                handleConfirm={handleAddConfirm}
                maxUsersCnt={latestLicense.maxUsersCnt}
                existedMemebers={members}
                fullContract={latestLicense.fullContract}
                allowedDomains={latestLicense.domain.split(", ")}
                currBizGroup={currBizGroup}
            />
            <DeleteMemberDialog
                open={deleteDialogOpen}
                handleClose={handleDeleteDialogClose}
                handleConfirm={handleDeleteConfirm}
            />
        </Toolbar>
    );
};

export const MembersContainer = observer(() => {
    const { memberStore, bizGroupStore, siteLicenseStore } = useStores();
    const { members } = memberStore;
    const { bizGroups } = bizGroupStore;
    const { latestLicense, userFilterSets, selectedFilterSet, setFilterSet, deleteUserFilterSet } = siteLicenseStore;
    const { bizGroupId:currBizGroupId } = useParams();

    const groupFilterType = {
        All: 'all',
        Verified: 'verified',
        Unproved: 'unproved'
    };

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('studentEmail');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [dense, setDense] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [groupValue, setGroupValue] = useState(groupFilterType.All);

    useEffect(() => {
        if(currBizGroupId !== -1) {
            setFilterSet(undefined);
            memberStore.findAll({bizGroupId: currBizGroupId});
            siteLicenseStore.findAll({bizGroupId: currBizGroupId});
            siteLicenseStore.findUserFilterSetAll({bizGroupId: currBizGroupId});
        }
    }, [currBizGroupId])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            setSelected(members);
            return;
        }
        setSelected([]);
    };

    const handleSelectedFilterSet = (filterSet) => {
        setFilterSet(filterSet);
        memberStore.findAll({bizGroupId: currBizGroupId, userFilterSetId: filterSet.id});
    }

    const handleClick = (event, member) => {
        const selectedIndex = selected.findIndex(m => m.studentEmail === member.studentEmail);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = [...selected, member];
        } else {
            newSelected = [...selected.slice(0, selectedIndex), ...selected.slice(selectedIndex+1)]
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (member) => selected.findIndex(m => m.studentEmail === member.studentEmail) !== -1;

    const clearSelected = () => {
        setSelected([])
    }

    const handleMemeberDelete = () => {
        clearSelected();
        memberStore.deleteMembers({
            bizGroupId: currBizGroupId,
            userFilterSetId: selectedFilterSet.id,
            members: selected
        })
    }

    const handleMemebersAdd = members => {
        memberStore.addMembers({
            bizGroupId: currBizGroupId,
            userFilterSetId: selectedFilterSet.id,
            members
        })
    }

    const handleGroupChange = e => {
        setGroupValue(e.target.value)
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - members.length) : 0;

    if (memberStore.isFetching || siteLicenseStore.isFetching) {
        return (
            <Stack alignItems={"center"} minHeight={'100vh'} justifyContent={'flex-start'} p={4} bgcolor={'#F3F6F9'}>
                <CircularProgress/>
            </Stack>
        )
    }

    return (latestLicense ? (
                <Paper sx={{ width: '100%', mb: 2 }}>
                <EnhancedTableToolbar
                    numSelected={selected.length}
                    members={members}
                    addMembers={handleMemebersAdd}
                    deleteMembers={handleMemeberDelete}
                    latestLicense={latestLicense}
                    userFilterSets={userFilterSets}
                    selectedFilterSet={selectedFilterSet}
                    deleteUserFilterSet={deleteUserFilterSet}
                    handleSelectedFilterSet={handleSelectedFilterSet}
                    clearSelected={clearSelected}
                    currBizGroup={bizGroups.find(({id}) => id === Number(currBizGroupId))}
                    />

                <FormControl sx={{px: 2}}>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={groupValue}
                        onChange={handleGroupChange}
                    >
                        <FormControlLabel value={groupFilterType.All} control={<Radio />} label={`전체(${members.length})`} />
                        <FormControlLabel value={groupFilterType.Verified} control={<Radio />} label={`인증됨(${members.filter(({verifiedDate}) => verifiedDate).length})`} />
                        <FormControlLabel value={groupFilterType.Unproved} control={<Radio />} label={`인증전(${members.filter(({verifiedDate}) => verifiedDate===null).length})`} />
                    </RadioGroup>
                </FormControl>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={members.length}
                        />
                        <TableBody>
                            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                            {stableSort(
                                members.map(member => ({
                                    ...member,
                                    lastLogin: member.lastLogin !== null ? moment(member.lastLogin).valueOf() : 0,
                                    verified: member.verifiedDate !== null,
                                    verifiedDate: member.verifiedDate !== null ? moment(member.verifiedDate).valueOf() : 0,
                                })),
                                getComparator(order, orderBy))
                                .filter(row => {
                                    if (groupValue === groupFilterType.All) {
                                        return true
                                    } else if (groupValue === groupFilterType.Verified) {
                                        return row.verifiedDate
                                    } else {
                                        return row.verifiedDate === null
                                    }
                                })
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            onClick={(event) => handleClick(event, row)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.studentEmail}
                                            selected={isItemSelected}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    color="primary"
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                />
                                            </TableCell>
                                            {/* <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="none"
                                            >
                                                {row.name}
                                            </TableCell> */}
                                            <TableCell>{row.username}</TableCell>
                                            <TableCell>{row.studentEmail}</TableCell>
                                            {/* <TableCell>{moment(row.requestedAt).format('YYYY-MM-DD')}</TableCell> */}
                                            <TableCell>{row.verifiedDate ? <Check /> : null}</TableCell>
                                            <TableCell>{row.verifiedDate ? moment(row.verifiedDate).format('YYYY-MM-DD') : null}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {/* <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={members.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                /> */}
            </Paper>

            ):(
                <Typography>라이센스 계약이 필요합니다</Typography>
            )
    );
})
