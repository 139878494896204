import React, { useEffect, useState } from "react"
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Stack, TextField, Typography, Box, IconButton, Table, TableHead, TableCell, TableRow, TableBody, Paper, Input, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons/faFileCsv';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {unifyLineBreak} from "../../../utils/string";

export const DIALOG_TYPE = {
    Manual: "Manual",
    File: "File"
}

export const AddMemberDialog = ({
    open,
    dialogType,
    handleClose,
    handleConfirm,
    maxUsersCnt,
    fullContract,
    existedMemebers,
    allowedDomains,
    currBizGroup
}) => {
    const initMember = {
        studentEmail: ''
    }

    const [uploadedCsv, setUploadedCsv] = useState(null)
    const [members, setMembers] = useState([initMember])
    const [alertOpen, setAlertOpen] = useState(false)
    const [termAgreed, setTermAgreed] = useState(false)

    const mockData = [
        ['이메일'],
        [`gildong.hong@${allowedDomains[0]}`],
        [`namwoo.kim@${allowedDomains[0]}`]
    ]

    const templateCSV = `data:text/csv;charset=utf-8,${mockData.map(data => data.join(',')).join('\n')}`

    const encodedURI = encodeURI(templateCSV);

    const handleFiles = e => {
        if (e.target.files[0]) {
            const f = e.target.files[0]
            if (f.type !== 'text/csv') {
                alert('csv 파일을 업로드해주세요.')
                return;
            }
            const reader = new FileReader();

            reader.onload = function (event) {
                const filteredCsv = unifyLineBreak(event.target.result);
                setUploadedCsv(filteredCsv)
                let lines = filteredCsv.split('\n')
                if (lines[0].split(',')[0] === '이메일') {
                    lines = lines.slice(1)
                }
                setMembers(lines.map(line => ({
                    studentEmail: line.split(',')[0]
                })))
            };
            reader.readAsText(f)
        }
    }

    const handleDeleteRow = idx => {
        setMembers([...members.slice(0, idx), ...members.slice(idx + 1)])
    }

    const handleAddRow = () => {
        setMembers(prev => [...prev, initMember])
    }

    const handleMemeberChange = (idx, key) => (e) => {
        setMembers([
            ...members.slice(0, idx),
            {
                ...members[idx],
                [key]: e.target.value
            },
            ...members.slice(idx + 1),
        ])
    }

    const clearInputs = () => {
        setUploadedCsv(null)
        setMembers([initMember])
    }

    const onClose = () => {
        const anyDirty = members.filter(({ studentEmail }) => studentEmail.length !== 0)
        if (anyDirty.length === 0) {
            // immediate close
            clearInputs();
            handleClose();
        } else {
            setAlertOpen(true)
        }
    }

    const onConfirm = () => {
        const unique = [... new Set(members.map(({studentEmail}) => studentEmail))]
        if (unique.length !== members.length) {
            alert("중복되는 메일이 있습니다.")
            return
        } else if (members.some(({studentEmail}) => studentEmail.trim().length === 0)) {
            alert("비어 있는 메일 주소가 있습니다.")
            return
        } else if (!fullContract && maxUsersCnt - (existedMemebers.length + members.length) < 0) {
            alert("정원을 초과하였습니다.")
            return
        } else if (unique.some(studentEmail => getErrorHelperText(studentEmail).error)) {
            alert("표시된 에러를 모두 해결해주세요.")
        } else {
            handleConfirm(members)
            clearInputs()
            handleClose();
        }
    }


    const getErrorHelperText = value => {
        if (!value || value.length === 0){
            return {
                error: false,
                helperText: ``
            }
        }

        if (!allowedDomains.some(domain => value.endsWith(domain))) {
            return {
                error: true,
                helperText: `메일은 다음중 하나여야 합니다 (${allowedDomains.join(', ')}).`
            }
        }

        return {
            error: false,
            helperText: ``
        }
    }

    const handleTerm = e => {
        setTermAgreed(e.target.checked)
    }


    return (
        <>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                disableEscapeKeyDown
                fullWidth
                maxWidth={'md'}
            >
                <DialogTitle id="alert-dialog-title">
                    사이트 라이센스 명단 추가
                </DialogTitle>
                {
                    dialogType === DIALOG_TYPE.Manual || uploadedCsv ? (
                        <>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    사이트 라이센스를 적용받을 이메일 목록을 완성해주세요.
                                </DialogContentText>
                            </DialogContent>
                            <DialogContent>

                                <Box display="grid" gridTemplateColumns={`50px 1fr`} rowGap={1} columnGap={2} p={2}>
                                    <Typography gridRow={1} gridColumn={1} textAlign={'center'}>#</Typography>
                                    <Typography gridRow={1} gridColumn={2}>학교 이메일</Typography>

                                    {members.map((member, memberIdx) => (
                                        <>
                                            <IconButton component="span" color="error" sx={{
                                                alignSelf: 'center',
                                                justifySelf: 'center',
                                                gridColumn: 1
                                            }} onClick={() => handleDeleteRow(memberIdx)}>
                                                <RemoveCircleIcon />
                                            </IconButton>
                                            <TextField
                                                type={"email"}
                                                gridColumn={2}
                                                required
                                                onChange={handleMemeberChange(memberIdx, 'studentEmail')}
                                                value={member['studentEmail']}
                                                error={getErrorHelperText(member['studentEmail']).error}
                                                helperText={getErrorHelperText(member['studentEmail']).helperText}
                                            />
                                        </>
                                    ))}

                                    <Button
                                        variant="contained"
                                        color="success"
                                        onClick={handleAddRow}
                                        sx={{
                                            gridColumn: `1 / 3`,
                                            boxShadow: 'none'
                                        }}
                                        disabled={(maxUsersCnt - (existedMemebers.length + members.length))<=0}>
                                            {fullContract ? `무제한 추가 가능` : `${maxUsersCnt - (existedMemebers.length + members.length)}명 추가로 기입 가능 (현재: ${existedMemebers.length + members.length}/ 정원: ${maxUsersCnt})`}
                                    </Button>
                                </Box>
                            </DialogContent>
                            <DialogContent sx={{py: 0}}>
                                <FormControlLabel checked={termAgreed} onChange={handleTerm} control={<Checkbox />} label={`학생들로부터 자신들의 접속기록과 공부 진행 정도에 대한 정보를 주식회사 브랜치앤바운드가 ${currBizGroup.name}에게 제공하는 것에 사전 동의를 필이 받았음`} />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={onClose}>나가기</Button>
                                <Button onClick={onConfirm} autoFocus variant="contained" disabled={!termAgreed}>
                                    작성 완료
                                </Button>
                            </DialogActions>
                        </>
                    ) : (
                        <>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    아래 양식으로 작성된 멤버 명단 파일 (확장자: '.csv')을 업로드 해주세요.
                                </DialogContentText>
                            </DialogContent>
                            <DialogContent sx={{
                                backgroundColor: '#dedede'
                            }}>
                                <Paper>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>이메일</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {mockData.slice(1).map((studentEmail) => (
                                                <TableRow key={studentEmail}>
                                                    <TableCell>{studentEmail}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Paper>
                            </DialogContent>
                            <Stack component={DialogActions} spacing={1} direction={'row'}>
                                <Button variant="contained" color="success" href={encodedURI} download={'member_template.csv'} endIcon={<FileDownloadIcon />}>템플릿 다운로드(.csv)</Button>
                                <label htmlFor="icon-button-file">
                                    <Input accept=".csv" id="icon-button-file" type="file" sx={{ display: 'none' }} onChange={handleFiles} />
                                    <Button component="span" variant="contained" endIcon={<FontAwesomeIcon icon={faFileCsv} />}>업로드</Button>
                                </label>
                                <Button variant="outlined" onClick={() => {
                                    clearInputs();
                                    handleClose();
                                }}>나가기</Button>
                            </Stack>

                        </>
                    )

                }
            </Dialog>
            <Dialog
                open={alertOpen}
                onClose={() => setAlertOpen(false)}
            >
                <DialogTitle>
                    나가시겠습니까?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        나갈 경우 작성중인 내역을 모두 잃어버리게 됩니다.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => setAlertOpen(false)}>편집 유지</Button>
                    <Button variant="contained" color="error" onClick={() => {
                        setAlertOpen(false)
                        clearInputs();
                        handleClose();
                    }}>나가기</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
